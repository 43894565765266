import React, { lazy, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { themeChange } from "theme-change";
import { useAuth } from "./app/auth";
import initializeApp from "./app/init";
import { H } from "highlight.run";
import { onValue, ref as dbRef } from "firebase/database";
import { useDispatch } from "react-redux";
import { openModal } from "./features/common/modalSlice";
import { MODAL_BODY_TYPES } from "./utils/globalConstantUtil";
import mixpanel from 'mixpanel-browser';


// Importing pages
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Register = lazy(() => import("./pages/Register"));
const Documentation = lazy(() => import("./pages/Documentation"));
const BlogCreator = lazy(() => import("./pages/protected/BlogCreator"));
const BlogEditor = lazy(() => import("./pages/protected/EditBlogs"));
const WatchYtVideo = lazy(() => import("./pages/protected/WatchYtVideo"));
const Blogs = lazy(() => import("./pages/protected/GeneratedBlogs"));
const CreatePostManually = lazy(() => import("./pages/protected/CreatePostManually"));

// Initializing different libraries
initializeApp();


//MIXPANEL
mixpanel.init('3aab11d76a4174259db7a3f500d3dbf5', {track_pageview: "url-with-path", persistence: 'localStorage'});
let urlParams = new URLSearchParams(window.location.search);
let distinctId = urlParams.get('distinctId');
if(distinctId) {
  console.log("distinctId", distinctId);
  
  console.log(mixpanel.identify(distinctId));
  
}

function App() {
  // Check for login and initialize axios
  const auth = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false);
  }, []);

  useEffect(() => {
    if (auth?.user?.["screenName"] || auth?.user?.["displayName"]) {
      listenToSubscriptionData();
    }

    // eslint-disable-next-line
  }, [auth?.user]);

  const listenToSubscriptionData = async () => {
    //listen to the subscription data from realtime user database
    if (auth && auth.user) {
      const subscriptionRef = dbRef(
        auth?.rtdb,
        "subscription/" + auth?.user.localId
      );
      onValue(subscriptionRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          if(data?.onBoardingComplete === false){
            dispatch(
                  openModal({
                    title: "",
                    size: "",
                    defaultType: "onBoarding",
                    bodyType: MODAL_BODY_TYPES.LEAD_ADD_NEW,
                  })
                );
          }
          try {
            const date = new Date(data["createdAt"]);
            const createdAt =
              date.getFullYear() +
              "-" +
              (date.getMonth() + 1) +
              "-" +
              date.getDate();
            H.identify(
              auth?.user?.["screenName"] || auth?.user?.["displayName"],
              {
                firebaseId: auth?.user?.["localId"],
                status: data["status"],
                createdAt: createdAt,
              }
            );
          } catch (error) {
            console.log("error : ", error);
          }
        }
      });
    }
  };

  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/documentation" element={<Documentation />} />
          <Route path="/blog-creator" element={<BlogCreator />} />
          <Route path="/post-editor" element={<BlogEditor />} />
          <Route path="/watch-youtube" element={<WatchYtVideo />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/post-creator" element={<CreatePostManually />} />
          {/* Place new routes over this */}
          <Route path="/app/*" element={<Layout />} />

          <Route
            path="*"
            element={
              <Navigate to={auth?.user ? "/app/dashboard" : "/login"} replace />
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
